import React from "react";
import { connect } from "react-redux";
import { ConfirmationContainer } from "tf-checkout-react";
import { Intent } from "@blueprintjs/core";
import { showToast } from "@actions/ui";

const CheckoutComplete = (props) => {
  const { showToast, hash } = props;

  return (
    <div className="checkout_complete_page container custom_container">
      <ConfirmationContainer
        isReferralEnabled={true}
        showDefaultShareButtons={true}
        orderHash={hash}
        onLinkCopied={() => {
          showToast("Copied to your clipboard", Intent.SUCCESS);
        }}
        confirmationLabels={{}}
      />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    showToast: (message, intentStyle, forever) => {
      dispatch(showToast(message, intentStyle, forever));
    },
  };
};

const mapStateToProps = () => {
  return {
    hash: window.location.pathname.split("/")[3],
  };
};

const CompleteRedux = connect(
  mapStateToProps,
  mapDispatchToProps
)(CheckoutComplete);

export default CompleteRedux;
